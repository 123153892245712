import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { Box, IconButton, Menu, MenuItem, TextField, Tooltip, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import folder from './assets/open-folder.png';
import cards from './assets/flash-card.png';

const ItemTypes = {
  NODE: 'node',
};

const TreeNode = ({ node, moveNode, onSelect, selectedFolderId, onCreateFolder, fetchTreeData }) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [creatingFolder, setCreatingFolder] = useState(false);
  const [creatingSubfolder, setCreatingSubfolder] = useState(false);
  const [newName, setNewName] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(node.name);
  const [plusAnchorEl, setPlusAnchorEl] = useState(null);
  const navigate = useNavigate();

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.NODE,
    item: { id: node.id },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  const [, drop] = useDrop({
    accept: ItemTypes.NODE,
    drop: (item, monitor) => {
      if (!monitor.didDrop()) {
        moveNode(item.id, node.id);
      }
    },
  });

  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handlePlusClick = (event) => {
    event.stopPropagation();
    setPlusAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setPlusAnchorEl(null);
  };

  const handleCreateClick = (type) => {
    if (node.type === 'subfolder') return;
    if (type === 'folder') {
      setCreatingFolder(true);
      setCreatingSubfolder(false);
    } else {
      setCreatingSubfolder(true);
      setCreatingFolder(false);
    }
    handleMenuClose();
  };

  const handleCreateSubmit = (e, type) => {
    e.preventDefault();
    if (newName.trim() === '') return;
    const apiUrl = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem('authToken');

    const url =
      type === 'folder'
        ? `${apiUrl}/folders/${node.id}/create_folder/`
        : `${apiUrl}/folders/${node.id}/create_subfolder_in_subfolder/`;

        fetch(url, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`, // Include the token in the headers
            'Content-Type': 'application/json',
          },
      body: JSON.stringify({ name: newName }),
    })
      .then((response) => response.json())
      .then((newFolder) => {
        setNewName('');
        setCreatingFolder(false);
        setCreatingSubfolder(false);
        fetchTreeData(); // Fetch the updated tree data
      })
      .catch((error) => console.error('Error creating folder:', error));
  };

  const handleEdit = () => {
    setIsEditing(true);
    handleMenuClose();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (editedName.trim() === '') return;

    const apiUrl = process.env.REACT_APP_BASE_URL;
    const token = sessionStorage.getItem("authToken");
    const url = `${apiUrl}/folders/${node.id}/`;

    fetch(url, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`, // Include the token in the Authorization header
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: editedName }),
    })
      .then((response) => response.json())
      .then(() => {
        setIsEditing(false);
        fetchTreeData(); // Fetch the updated tree data
      })
      .catch((error) => console.error("Error editing folder:", error));
  };

    // Handle delete confirmation
    const handleDeleteClick = () => {
      setOpenDialog(true); // Open the delete confirmation dialog
    };
  
    const handleCloseDialog = () => {
      setOpenDialog(false); // Close dialog without deleting
    };
  

  const handleDelete = () => {
    const apiUrl = process.env.REACT_APP_BASE_URL;
    
    const url = `${apiUrl}/folders/${node.id}/`;

    fetch(url, {
      method: 'DELETE',
    })
      .then(() => {
        fetchTreeData(); // Fetch the updated tree data after deletion
      })
      .catch((error) => console.error('Error deleting folder:', error));
      
    setOpenDialog(false); // Close the dialog after deleting
    setAnchorEl(null); // Close the menu
  };
  
  const handlePracticeFlashcards = (event) => {
    event.stopPropagation();
    navigate(`/practiceflashcard/${node.id}`);
    handleMenuClose(); // Close the menu after navigation
  };

  const hasChildren = node.children && node.children.length > 0;
  const isSelected = node.id === selectedFolderId;

  const handleNodeClick = () => {
    if (node.type === 'subfolder') {
      navigate(`/cards/${node.id}`);
    } else {
      setExpanded(!expanded);
    }
    onSelect(node.id);  // Update selected folder when clicked
  };

  // Add a highlight style for the selected folder
  const highlightStyle = isSelected ? { backgroundColor: 'rgb(124, 182, 246)', borderRadius: '4px', color: 'blue' } : {};

  return (
    <div ref={drop} style={{ opacity: isDragging ? 0.5 : 1 }} className={`tree-node ${isSelected ? 'selected' : ''}`}>
      <div ref={drag} className={hasChildren ? (expanded ? 'expanded' : 'collapsed') : ''} onClick={handleNodeClick} style={{ display: 'flex', alignItems: 'center', ...highlightStyle }}>
        {hasChildren && (expanded ? <KeyboardArrowDownIcon /> : <ArrowForwardIosIcon style={{ fontSize: 'small' }} />)}
        <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1, overflow: 'hidden' }}>
          {isEditing ? (
            <form onSubmit={handleEditSubmit} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <TextField
                value={editedName}
                onChange={(e) => setEditedName(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                autoFocus
                margin="dense"
              />
              <IconButton type="submit" size="small">
                <AddIcon />
              </IconButton>
            </form>
          ) : (
            <>
              <Typography noWrap style={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                {node.type === 'folder' ? <img src={folder} alt="Folder Icon" style={{ width: 24, height: 24, marginRight: 8 }} /> : <img src={cards} alt="Subfolder Icon" style={{ width: 24, height: 22, marginRight: 8 }} />}
                <Tooltip title={node.name} arrow>
                  <span>{node.name}</span>
                </Tooltip>
              </Typography>
              {node.type !== 'subfolder' && (
                <IconButton onClick={handlePlusClick} size="small">
                  <AddIcon style={{ fontSize: 'medium' }} />
                </IconButton>
              )}
              <IconButton onClick={handleMenuClick} size="small">
                <MoreVertIcon style={{ fontSize: 'medium' }} />
              </IconButton>
              <Menu anchorEl={plusAnchorEl} open={Boolean(plusAnchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => handleCreateClick('folder')} disabled={node.type === 'subfolder'}>
                  Create Folder
                </MenuItem>
                <MenuItem onClick={() => handleCreateClick('subfolder')} disabled={node.type === 'subfolder'}>
                  Create Subfolder
                </MenuItem>
              </Menu>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {node.type === 'subfolder' && (
                  <MenuItem onClick={handlePracticeFlashcards}>Practice Flashcards</MenuItem>
                )}
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
    {node.type === 'subfolder' && (
        <MenuItem onClick={handlePracticeFlashcards}>Practice Flashcards</MenuItem>
    )}
    <MenuItem onClick={handleEdit}>{node.type === 'folder' ? 'Rename Folder' : 'Rename Subfolder'}</MenuItem>
    <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>
</Menu>

              </Menu>
              {/* Delete Confirmation Dialog */}
              <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Delete Confirmation</DialogTitle>
                <DialogContent>
    <DialogContentText>
      Are you sure you want to delete this folder? This action cannot be undone.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCloseDialog} color="primary">
      Cancel
    </Button>
    <Button onClick={handleDelete} color="error">
      Delete
    </Button>
  </DialogActions>
</Dialog>
            </>
          )}
        </Box>
      </div>
      {creatingFolder && (
        <form onSubmit={(e) => handleCreateSubmit(e, 'folder')}>
          <TextField
            label="Enter folder name press enter"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            required
            variant="outlined"
            size="small"
            autoFocus
            fullWidth
            margin="dense"
          />
          <IconButton type="submit" size="small">
            <AddIcon />
          </IconButton>
        </form>
      )}
      {creatingSubfolder && (
        <form onSubmit={(e) => handleCreateSubmit(e, 'subfolder')}>
          <TextField
            label="Enter subfolder name,press enter"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            required
            variant="outlined"
            size="small"
            autoFocus
            fullWidth
            margin="dense"
          />
          <IconButton type="submit" size="small">
            <AddIcon />
          </IconButton>
        </form>
      )}
      {expanded && node.children && node.children.map((childNode) => (
        <TreeNode
          key={childNode.id}
          node={childNode}
          moveNode={moveNode}
          onSelect={onSelect}
          selectedFolderId={selectedFolderId}
          onCreateFolder={onCreateFolder}
          fetchTreeData={fetchTreeData}
        />
      ))}
    </div>
  );
};

export default TreeNode;
