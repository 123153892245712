import React, { useState, useEffect } from 'react'; 
import TreeNode from './TreeNode';
import CreateFolderForm from './CreateFolderForm';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const TreeView = () => {
  const [treeData, setTreeData] = useState([]);
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [selectedNodeType, setSelectedNodeType] = useState(null);
  const [isDuplicateAlertOpen, setIsDuplicateAlertOpen] = useState(false);

  const token = sessionStorage.getItem("authToken");

  useEffect(() => {
    fetchTreeData();
  }, []);

 const fetchTreeData = () => {
   const token = sessionStorage.getItem("authToken"); // Fetch the token from localStorage or a secure source
   if (!token) {
     console.error("No authentication token found");
     return;
   }

   fetch("http://warals1.ddns.net:9008/folders/", {
     headers: {
       Authorization: `Bearer ${token}`, // Include Bearer token here
       "Content-Type": "application/json", // Optional: Specify content type
     },
   })
     .then((response) => {
       if (!response.ok) {
         throw new Error(`HTTP error! Status: ${response.status}`);
       }
       return response.json();
     })
     .then((data) => {
       setTreeData(formatTreeData(data)); // Process and save the data
     })
     .catch((error) => {
       console.error("Error fetching data:", error);
     });
 };

  const formatTreeData = (data) => {
    const tree = [];

    const map = new Map();
    data.forEach((item) => {
      map.set(item.id, { ...item, children: [] });
    });

    data.forEach((item) => {
      if (item.parent === null) {
        tree.push(map.get(item.id));
      } else {
        map.get(item.parent).children.push(map.get(item.id));
      }
    });

    return tree;
  };

  const findNode = (id, nodes) => {
    for (let node of nodes) {
      if (node.id === id) return node;
      if (node.children) {
        const found = findNode(id, node.children);
        if (found) return found;
      }
    }
    return null;
  };

  const moveNode = (sourceId, destinationId) => {
    const sourceNode = findNode(sourceId, treeData);
    const destinationNode = findNode(destinationId, treeData);

    if (!sourceNode || !destinationNode) return;

    if (destinationNode.type === 'subfolder') {
      alert('Nothing can be dragged inside a subfolder.');
      return;
    }

    const apiUrl = process.env.REACT_APP_BASE_URL;
    const url =
      sourceNode.type === 'folder'
        ? `${apiUrl}/folders/${sourceId}/move_folder/`
        : `${apiUrl}/subfolders/${sourceId}/move_subfolder/`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include Bearer token here
      },
      body: JSON.stringify({ new_parent_id: destinationId }),
    })
      .then((response) => response.json())
      .then(() => {
        const newTreeData = JSON.parse(JSON.stringify(treeData));

        const removeNode = (id, nodes) => {
          return nodes.filter((node) => {
            if (node.id === id) return false;
            if (node.children) {
              node.children = removeNode(id, node.children);
            }
            return true;
          });
        };

        const updatedTreeData = removeNode(sourceId, newTreeData);

        const addNode = (parentId, node, nodes) => {
          return nodes.map((n) => {
            if (n.id === parentId) {
              if (!n.children) {
                n.children = [];
              }
              n.children.push(node);
            } else if (n.children) {
              n.children = addNode(parentId, node, n.children);
            }
            return n;
          });
        };

        setTreeData(addNode(destinationId, sourceNode, updatedTreeData));
      })
      .catch((error) => console.error("Error moving folder:", error));
  };

  const handleCreateFolder = ({ name, type }) => {
    const parentId = selectedFolderId;
    const apiUrl = process.env.REACT_APP_BASE_URL;

    const url =
      type === 'folder'
        ? `${apiUrl}/folders/`
        : `${apiUrl}/folders/${parentId}/create_subfolder/`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Include Bearer token here
      },
      body: JSON.stringify({ name }),
    })
      .then((response) => {
        if (response.status === 400) {
          // Show the duplicate folder alert dialog if the status is 400
          setIsDuplicateAlertOpen(true);
          throw new Error("Folder name already exists");
        }
        return response.json();
      })
      .then((newFolder) => {
        fetchTreeData();
      })
      .catch((error) => console.error("Error creating folder:", error));
  };

  const handleSelect = (id, type) => {
    setSelectedFolderId(id);
    setSelectedNodeType(type);
  };

  const handleCloseDuplicateAlert = () => {
    setIsDuplicateAlertOpen(false);
  };

  return (
    <Box>
      <CreateFolderForm onCreateFolder={handleCreateFolder} disableCreate={selectedNodeType === 'subfolder'} />
      {treeData.map((node) => (
        <TreeNode
          key={node.id}
          node={node}
          moveNode={moveNode}
          onSelect={handleSelect}
          selectedFolderId={selectedFolderId}
          onCreateFolder={handleCreateFolder}
          fetchTreeData={fetchTreeData}
        />
      ))}
      <Dialog open={isDuplicateAlertOpen} onClose={handleCloseDuplicateAlert}>
        <DialogTitle>Duplicate Folder</DialogTitle>
        <DialogContent>
          <p>Folder with the same name already exists.</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDuplicateAlert} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TreeView;
