import React, { useState, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const ImageOcclusion = () => {
  const [imageFile, setImageFile] = useState(null);
  const [occlusions, setOcclusions] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [showAnswers, setShowAnswers] = useState(false);
  const canvasRef = useRef(null);
  const { subfolder_id } = useParams();

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageFile(e.target.result);
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  });

  const uploadImage = async (file) => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      
      // Replace <subfolder_id> with the actual ID
      // Example ID, replace with actual
      const response = await axios.post(
        `http://warals1.ddns.net:9008/subfolder/${subfolder_id}/uploaded_images/`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );
      console.log('Image uploaded:', response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  useEffect(() => {
    if (imageFile) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = imageFile;
      img.onload = () => {
        const maxWidth = 600; // Max width for the canvas
        const aspectRatio = img.width / img.height;
        canvas.width = maxWidth;
        canvas.height = maxWidth / aspectRatio;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
    }
  }, [imageFile]);

  useEffect(() => {
    if (imageFile) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = imageFile;
      img.onload = () => {
        const maxWidth = 600;
        const aspectRatio = img.width / img.height;
        canvas.width = maxWidth;
        canvas.height = maxWidth / aspectRatio;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        occlusions.forEach((occlusion) => {
          if (!showAnswers || !occlusion.hidden) {
            ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
            ctx.fillRect(occlusion.x, occlusion.y, occlusion.width, occlusion.height);
          }
        });
      };
    }
  }, [imageFile, occlusions, showAnswers]);

  const handleMouseDown = (event) => {
    if (!imageFile) return;
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    setIsDrawing(true);
    setStartPoint({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    });
  };

  const handleMouseMove = (event) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const currentPoint = {
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    };

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const img = new Image();
    img.src = imageFile;
    img.onload = () => {
      const maxWidth = 600;
      const aspectRatio = img.width / img.height;
      canvas.width = maxWidth;
      canvas.height = maxWidth / aspectRatio;
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      occlusions.forEach((occlusion) => {
        if (!showAnswers || !occlusion.hidden) {
          ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
          ctx.fillRect(occlusion.x, occlusion.y, occlusion.width, occlusion.height);
        }
      });

      const width = currentPoint.x - startPoint.x;
      const height = currentPoint.y - startPoint.y;
      ctx.fillStyle = 'rgba(0, 0, 0, 0.5)';
      ctx.fillRect(startPoint.x, startPoint.y, width, height);
    };
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = Math.abs(ctx.fillRect.width);
    const height = Math.abs(ctx.fillRect.height);
    if (width && height) {
      setOcclusions((prev) => [
        ...prev,
        {
          x: Math.min(startPoint.x, startPoint.x + width),
          y: Math.min(startPoint.y, startPoint.y + height),
          width: Math.abs(width),
          height: Math.abs(height),
          hidden: true,
        },
      ]);
    }
  };

  const handleShowAnswers = () => {
    setShowAnswers(true);
  };

  return (
    <div>
      <div {...getRootProps()} style={{ border: '2px dashed #cccccc', padding: '20px', cursor: 'pointer' }}>
        <input {...getInputProps()} />
        <p>Drag & drop an image here, or click to select one</p>
      </div>
      <div
        style={{
          maxWidth: '600px',
          overflowX: 'hidden',
          overflowY: 'auto',
          marginTop: '20px',
          border: '1px solid #cccccc',
        }}
      >
        <canvas
          ref={canvasRef}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          style={{ cursor: 'crosshair' }}
        />
      </div>
      <Button variant="contained" onClick={handleShowAnswers} style={{ marginTop: '10px' }}>
        Show Answer
      </Button>
    </div>
  );
};

export default ImageOcclusion;
