import React, { useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import NotesIcon from "@mui/icons-material/Notes";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SettingsIcon from "@mui/icons-material/Settings";
import Avatar from "@mui/material/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ShareIcon from "@mui/icons-material/Share";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LogoutIcon from "@mui/icons-material/Logout";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import DeleteIcon from "@mui/icons-material/Delete";
import FlashOnOutlinedIcon from "@mui/icons-material/FlashOnOutlined";
import StarIcon from "@mui/icons-material/Star";
import CardsDisplay from "./CardsDisplay";
import Flashcard from "./FlashcardEditor";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DescriptionIcon from "@mui/icons-material/Description";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import SubfolderIcon from "@mui/icons-material/FolderSpecial";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TreeView from "./TreeView";
import flash from "./assets/thunder.png";
import notes from "./assets/note.png";
import { Link } from "react-router-dom";
import { LiveHelp } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const initialDrawerWidth = 300;
const minDrawerWidth = 100;
const maxDrawerWidth = 600;

const openedMixin = (theme, drawerWidth) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme, drawerWidth),
    "& .MuiDrawer-paper": openedMixin(theme, drawerWidth),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ItemTypes = {
  ITEM: "item",
};

const DraggableListItem = ({ item, index, moveItem, setEditingIndex }) => {
  const ref = React.useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.ITEM,
    hover(draggedItem) {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITEM,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));

  return (
    <ListItem
      ref={ref}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      disablePadding
      sx={{ display: "block", pl: 4 }}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: "initial",
          px: 2.5,
          position: "relative",
          "&:hover .edit-delete-buttons": {
            display: "flex",
          },
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: 3,
            justifyContent: "center",
            color: "inherit",
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText primary={item.text} sx={{ color: "inherit" }} />
        <Box
          className="edit-delete-buttons"
          sx={{
            display: "none",
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translateY(-50%)",
            gap: 1,
          }}
        >
          <IconButton onClick={() => setEditingIndex(index)} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton size="small">
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      </ListItemButton>
    </ListItem>
  );
};

export default function MiniDrawer({ content }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [drawerWidth, setDrawerWidth] = React.useState(initialDrawerWidth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [createAnchorEl, setCreateAnchorEl] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [newItemType, setNewItemType] = React.useState(null);
  const [newItemName, setNewItemName] = React.useState("");
  const [isEditing, setIsEditing] = React.useState(false);
  const [editingIndex, setEditingIndex] = React.useState(null);
  const [subfolderName, setSubfolderName] = React.useState("");
  const [parentFolderName, setParentFolderName] = React.useState("");
  const { subfolder_id } = useParams();
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    // Fetch data from the API and match subfolderId
    const fetchFolders = async () => {
      try {
        const token = sessionStorage.getItem("authToken"); // Retrieve the token from sessionStorage
        const response = await fetch("http://warals1.ddns.net:9008/folders/", {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Recursively find subfolder by id
        const findSubfolder = (folders, id) => {
          for (const folder of folders) {
            if (folder.id === parseInt(id)) {
              return folder;
            }
            const found = findSubfolder(folder.subfolders, id);
            if (found) return found;
          }
          return null;
        };

        const subfolder = findSubfolder(data, subfolder_id);
        if (subfolder) {
          setSubfolderName(subfolder.name);
          const parent = data.find((folder) => folder.id === subfolder.parent);
          if (parent) {
            setParentFolderName(parent.name);
          }
        }
      } catch (error) {
        console.error("Error fetching folders:", error);
      }
    };

    if (subfolder_id) {
      fetchFolders();
    }
  }, [subfolder_id]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMouseDown = (e) => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = e.clientX > minDrawerWidth ? e.clientX : minDrawerWidth;
    setDrawerWidth(Math.min(newWidth, maxDrawerWidth));
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCreateMenuClick = (event) => {
    setCreateAnchorEl(event.currentTarget);
  };

  const handleCreateMenuClose = (type) => {
    if (type) {
      setNewItemType(type);
    }
    setCreateAnchorEl(null);
  };

  const handleNewItemSubmit = (e) => {
    if (e.key === "Enter" && newItemName) {
      if (isEditing) {
        const updatedItems = [...items];
        updatedItems[editingIndex].text = newItemName;
        setItems(updatedItems);
        setIsEditing(false);
        setEditingIndex(null);
      } else {
        const newItem = { text: newItemName, icon: getIcon(newItemType) };
        setItems((prevItems) => [...prevItems, newItem]);
      }
      setNewItemType(null);
      setNewItemName("");
    }
  };

  const handleDeleteItem = (index) => {
    setItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleEditItem = (index) => {
    setNewItemName(items[index].text);
    setIsEditing(true);
    setEditingIndex(index);
    setNewItemType(items[index].type);
  };

  const getIcon = (type) => {
    switch (type) {
      case "Folder":
        return <FolderIcon color="secondary" />;
      case "Subfolder":
        return <SubfolderIcon color="action" />;

      default:
        return null;
    }
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const updatedItems = [...items];
    const draggedItem = updatedItems.splice(dragIndex, 1)[0];
    updatedItems.splice(hoverIndex, 0, draggedItem);
    setItems(updatedItems);
  };

  const drawerItems = [
    // { text: 'All Notes', icon: <img src={notes} style={{ width: 28, height: 28 }} /> },

    {
      text: "Flash Card",
      icon: <img src={flash} style={{ width: 28, height: 28 }} />,
    },

    { text: <TreeView /> },
  ];

  const createMenuItems = ["Folder", "Subfolder"];

  const menuItems = [
    { text: "Import", icon: <ImportExportIcon /> },
    { text: "Share Flashcards", icon: <FlashOnOutlinedIcon /> },
    { text: "Upgrade to Premium", icon: <StarIcon /> },
    { text: "Settings", icon: <SettingsIcon />, route: "/profilesetting" },
    { text: "Trash", icon: <DeleteIcon /> },
    { text: "Logout", icon: <LogoutIcon /> },
  ];

  const fullName = sessionStorage.getItem("fullName") || "User";

  return (
    // <DndProvider backend={HTML5Backend}>
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        open={open}
        drawerWidth={drawerWidth}
        sx={{ color: "black", boxShadow: "none", backgroundColor: "white" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
              background: "linear-gradient(to right, #142be1, #44a6e9)",
              color: "white",
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            className="custom-typography"
          >
            {parentFolderName
              ? `${parentFolderName} >> ${subfolderName} `
              : subfolderName || "Flash Card"}
          </Typography>
          <Box sx={{ flexGrow: 1 }} />
          <Tooltip title="See KaTeX reference to add mathematical expression">
            <IconButton
              color="inherit"
              component="a"
              href="https://katex.org/docs/supported.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <LiveHelp />
            </IconButton>
          </Tooltip>
          <IconButton color="inherit">
            <MoreVertIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} drawerWidth={drawerWidth}>
        <DrawerHeader
          sx={{
            background: "linear-gradient(to right, #142be1, #44a6e9)",
            color: "white",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {/* User Section */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            <div
              style={{
                backgroundColor: "#4a5568",
                width: "48px",
                height: "48px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "24px",
                color: "white",
                border: "2px solid white",
              }}
            >
              😊
            </div>
            {open && (
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 600,
                  fontSize: "1.2rem",
                  color: "white",
                }}
              >
                Welcome, {fullName.split(" ")[0]}!
              </Typography>
            )}
          </Box>

          {/* Menu and Drawer Controls */}
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {/* Menu Icon */}
            <IconButton
              onClick={handleMenuClick}
              sx={{
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              <KeyboardDoubleArrowDownIcon />
            </IconButton>
            {/* Dropdown Menu */}
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              PaperProps={{
                sx: {
                  background: "#ffffff",
                  color: "#000000",
                  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
                },
              }}
            >
              {menuItems.map((item) => (
                <MenuItem
                  key={item.text}
                  onClick={handleMenuClose}
                  component={Link}
                  to={item.route}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#f0f0f0",
                    },
                  }}
                >
                  <ListItemIcon sx={{ color: "#44a6e9" }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </MenuItem>
              ))}
            </Menu>

            {/* Drawer Close Icon */}
            <IconButton
              onClick={handleDrawerClose}
              sx={{
                color: "white",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.1)",
                },
              }}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => navigate("/home")}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Go to Home Page" />
            </ListItemButton>
          </ListItem>
        </List>

        <List style={{ marginTop: 3 }}>
          {drawerItems.map((item) => (
            <ListItem key={item.text} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={item.onClick}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "inherit",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0, color: "inherit" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
          {newItemType && (
            <Box sx={{ display: "flex", alignItems: "center", px: 2.5, py: 1 }}>
              <TextField
                label={`Enter ${newItemType} Name`}
                value={newItemName}
                onChange={(e) => setNewItemName(e.target.value)}
                onKeyDown={handleNewItemSubmit}
                fullWidth
                size="small"
              />
            </Box>
          )}
          {items.map((item, index) => (
            <DraggableListItem
              key={index}
              item={item}
              index={index}
              moveItem={moveItem}
              setEditingIndex={setEditingIndex}
            />
          ))}
        </List>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            width: "5px",
            cursor: "col-resize",
            zIndex: theme.zIndex.drawer + 1,
          }}
          onMouseDown={handleMouseDown}
        />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <main>
          {/* <Flashcard /> */}
          {content}
        </main>
      </Box>
      <Menu
        anchorEl={createAnchorEl}
        open={Boolean(createAnchorEl)}
        onClose={() => handleCreateMenuClose()}
      >
        {createMenuItems.map((type) => (
          <MenuItem key={type} onClick={() => handleCreateMenuClose(type)}>
            <ListItemIcon>{getIcon(type)}</ListItemIcon>
            <ListItemText primary={type} />
          </MenuItem>
        ))}
      </Menu>
    </Box>
    // </DndProvider>
  );
}
