import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; // Make sure you import CardContent correctly
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import skip from './assets/skip.png';
import forgot from './assets/forgot.png';
import partially from './assets/partially.png';
import efforts from './assets/efforts.png';
import convenient from './assets/convenient.png';
import { useNavigate, useParams } from 'react-router-dom';
import katex from 'katex'; // Import KaTeX for LaTeX rendering
import sanitizeHtml from 'sanitize-html'; // Import sanitize-html for sanitizing HTML content
import 'katex/dist/katex.min.css'; // KaTeX styles



const FlipCard = styled(Card)(({ theme }) => ({
  width: '80%',
  height: '80%',
  perspective: 1000,
  position: 'relative',
  backgroundColor: 'white',
  borderRadius: '20px',
}));

const FlipCardInner = styled('div')(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  transition: 'transform 0.8s',
  transformStyle: 'preserve-3d',
}));

const FlipCardFront = styled(CardContent)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  backfaceVisibility: 'hidden',
  backgroundColor: 'white',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1627af',
  color: 'white',
  width: '100%',
  height: '60px',
  fontSize: '1.25rem',
  '&:hover': {
    backgroundColor: '#44a6e9',
  },
}));

const ImageButton = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const Image = styled('img')(({ theme }) => ({
  width: '60px',
  height: '60px',
}));

// Utility function to strip HTML tags
const stripHtmlTags = (text) => {
  if (!text) return '';
  return text.replace(/<[^>]*>/g, '');
};

// Function to render LaTeX and HTML content
const renderContent = (html) => {
  if (!html) return null;

  // Split the content by LaTeX syntax
  const parts = html.split(/(\$\$.+?\$\$|\$.+?\$)/g); // Split by LaTeX syntax

  return parts.map((part, index) => {
    if (part.startsWith('$$') && part.endsWith('$$')) {
      // Block-level KaTeX
      const latex = part.slice(2, -2); // Remove the $$ delimiters
      try {
        const renderedLatex = katex.renderToString(latex, { displayMode: true });
        return (
          <div
            key={index}
            dangerouslySetInnerHTML={{ __html: renderedLatex }}
            className="rendered-content"
          />
        );
      } catch (err) {
        console.error('KaTeX rendering failed:', err);
        return <span key={index}>{part}</span>;
      }
    } else if (part.startsWith('$') && part.endsWith('$')) {
      // Inline KaTeX
      const latex = part.slice(1, -1); // Remove the $ delimiters
      try {
        const renderedLatex = katex.renderToString(latex);
        return (
          <span
            key={index}
            dangerouslySetInnerHTML={{ __html: renderedLatex }}
            className="rendered-content"
          />
        );
      } catch (err) {
        console.error('KaTeX rendering failed:', err);
        return <span key={index}>{part}</span>;
      }
    } else {
      // Sanitize and render HTML content
      const cleanHtml = sanitizeHtml(part, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          img: ['src', 'alt'],
          a: ['href', 'target'],
        },
      });
      return (
        <span
          key={index}
          dangerouslySetInnerHTML={{ __html: cleanHtml }}
          className="rendered-content"
        />
      );
    }
  });
};

export default function McqCard() {
  const [flashcards, setFlashcards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const { subfolder_id } = useParams();
  const { text } = useParams();
  const navigate = useNavigate(); 

   useEffect(() => {
     const fetchFlashcards = async () => {
       try {
         const token = sessionStorage.getItem("authToken"); // Retrieve the token 
         if (!token) {
           console.error("No authentication token found");
           return;
         }

         const response = await fetch(
           `http://warals1.ddns.net:9008/subfolder/${subfolder_id}/mcq/`,
           {
             headers: {
               Authorization: `Bearer ${token}`, // Include Bearer token here
               "Content-Type": "application/json", // Optional: Specify content type
             },
           }
         );

         if (!response.ok) {
           throw new Error(`HTTP error! Status: ${response.status}`);
         }

         const data = await response.json();

         // Map the MCQ data to include only the correct answers
         const processedMcqData = data.map((mcq) => ({
           ...mcq,
           answers:
             mcq.answers.find((answer) => answer.is_correct)?.answer_text || "",
         }));
         setFlashcards(processedMcqData);
       } catch (error) {
         console.error("Error fetching data:", error);
       }
     };

     fetchFlashcards();
   }, [subfolder_id, text]);

  const handleFlip = () => {
    setShowAnswer(!showAnswer);
  };

  const handleNext = async (feedback) => {
    if (currentIndex < flashcards.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else {
      setCurrentIndex(0);
    }
    setShowAnswer(false);

    try {
      const token = sessionStorage.getItem("authToken"); // Retrieve the token from localStorage
      if (!token) {
        console.error("No authentication token found");
        return;
      }

      const questionId = flashcards[currentIndex].id;
      const question_type = flashcards[currentIndex].question_type;
      const apiUrl = process.env.REACT_APP_BASE_URL;

      const response = await fetch(
        `${apiUrl}/subfolder/106/feedbacks/${question_type}/${questionId}/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Include Bearer token in the headers
          },
          body: JSON.stringify({ feedback }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      console.log("Feedback sent successfully.");
    } catch (error) {
      console.error("Error sending feedback:", error);
    }
  };


  const handleClose = () => {
    navigate(-1); // Navigate back to the previous page in the history
  };
  if (flashcards.length === 0) {
    return <div>Loading...</div>;
  }
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        background: "linear-gradient(to right, #142be1, #44a6e9)",
      }}
    >
      <FlipCard>
        <FlipCardInner>
          <FlipCardFront>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                p: 1,
              }}
            >
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box
              sx={{
                maxHeight: "400px", // Set maximum height for the scrollable area
                overflowY: "auto", // Enable vertical scrolling
                padding: 2,
                border: "1px solid #ccc", // Optional: Add a border for better visibility
                borderRadius: "8px",
                backgroundColor: "#f9f9f9",
                width: "100%",
              }}
            >
              <Typography
                variant="h4"
                component="div"
                sx={{
                  textAlign: "center",
                  wordWrap: "break-word",
                  padding: 2,
                }}
              >
                <strong>Question:</strong>{" "}
                {renderContent(
                  stripHtmlTags(flashcards[currentIndex].statement)
                )}
              </Typography>
              {showAnswer && (
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    textAlign: "center",
                    mt: 2,
                    wordWrap: "break-word",
                    padding: 2,
                  }}
                >
                  <strong>Answer:</strong>{" "}
                  {renderContent(
                    stripHtmlTags(flashcards[currentIndex].answers)
                  )}
                </Typography>
              )}
              {showAnswer && flashcards[currentIndex].explanation && (
                <Typography
                  variant="h5"
                  component="div"
                  sx={{
                    textAlign: "center",
                    mt: 2,
                    wordWrap: "break-word",
                    padding: 2,
                  }}
                >
                  <strong>Explanation:</strong>{" "}
                  {renderContent(
                    stripHtmlTags(flashcards[currentIndex].explanation)
                  )}
                </Typography>
              )}
            </Box>
            <CardActions sx={{ width: "100%" }}>
              {!showAnswer && (
                <StyledButton
                  variant="contained"
                  onClick={handleFlip}
                  startIcon={<VisibilityIcon />}
                >
                  Show Answer
                </StyledButton>
              )}
            </CardActions>
            {showAnswer && (
              <Box sx={{ width: "100%", mt: 2 }}>
                <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  <ImageButton onClick={() => handleNext("skip")}>
                    <Image src={skip} alt="skip" />
                    <Typography variant="caption">Skip</Typography>
                  </ImageButton>
                  <ImageButton onClick={() => handleNext("forgot")}>
                    <Image src={forgot} alt="forgot" />
                    <Typography variant="caption">Forgot</Typography>
                  </ImageButton>
                  <ImageButton onClick={() => handleNext("partially recalled")}>
                    <Image src={partially} alt="partially" />
                    <Typography variant="caption">
                      Partially Recalled
                    </Typography>
                  </ImageButton>
                  <ImageButton
                    onClick={() => handleNext("recalled with efforts")}
                  >
                    <Image src={efforts} alt="efforts" />
                    <Typography variant="caption">
                      Recalled with Efforts
                    </Typography>
                  </ImageButton>
                  <ImageButton onClick={() => handleNext("easily recalled")}>
                    <Image src={convenient} alt="convenient" />
                    <Typography variant="caption">Easily Recalled</Typography>
                  </ImageButton>
                </Box>
              </Box>
            )}
          </FlipCardFront>
        </FlipCardInner>
      </FlipCard>
    </Box>
  );
}