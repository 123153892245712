import logo from './logo.svg';
import './App.css';
import CardsDisplay from './components/CardsDisplay';
import Flashcard from './components/FlashcardEditor';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import FolderManager from './components/FolderManager';
import Dash from './components/Dash';
import MiniDrawer from './components/Drawer';
import App1 from './App1';
import FillInTheBlanks from './FillInTheBlanks';
import SingleLine from './SingleLine';
import TreeView from './components/TreeView'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TableComponent from './components/TableComponent';
import Login from './components/Login';
import Signup from './components/Signup';
import ResetPassword from './components/ResetPassword';
import ProfilePage from './components/ProfilePage';
import ProfileSettings from './components/ProfileSetting';
import EnhancedEditor from './components/EnhancedEditor';
import PracticeCard from './components/PracticeCard';
import McqCard from './components/McqCard';
import FolderHierarchyPage from './components/FolderHierarchyPage';
import PracSpaRep from './components/PractSpaRep';
import McqSpaced from './components/McqSpaced';
import Dashboard from './components/Dashboard';




  function App() {
    const theme = createTheme({
      typography: {
        fontFamily: ['Montserrat', 'poppins'].join(','),
      },
    });
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <DndProvider backend={HTML5Backend}>
            <Routes>
              <Route path="/signup" element={<Signup />} />
              <Route
                path="/set-new-password/:userid/:token"
                element={<ResetPassword />}
              />
              <Route path="/" element={<Login />} />
              <Route
                path="/home"
                element={<MiniDrawer content={<FolderHierarchyPage />} />}
              />
              <Route
                path="/profilesetting"
                element={<MiniDrawer content={<ProfileSettings />} />}
              />
              <Route
                path="/profilepage"
                element={<MiniDrawer content={<ProfilePage />} />}
              />

              <Route
                path="/cards/:subfolder_id"
                element={<MiniDrawer content={<Flashcard />} />}
              />
              <Route
                path="/practiceflashcard/:subfolder_id"
                element={<CardsDisplay />}
              />
              <Route
                path="/practiceparticularcard/:subfolder_id/:text"
                element={<PracticeCard />}
              />
              <Route
                path="/practiceparticularcard/:subfolder_id/mcqs"
                element={<McqCard />}
              />
              <Route
                path="/practicespacedrep/:subfolder_id/:text"
                element={<PracSpaRep />}
              />
              <Route
                path="/practicemcqspaced/:subfolder_id/mcqs"
                element={<McqSpaced />}
              />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </DndProvider>
        </Router>
        {/* <EnhancedEditor/> */}
        {/* <TableComponent/> */}
        {/* <MathEditor/> */}
        {/* <ProfilePage/> */}
        {/* <ProfileSettings/> */}
        {/* <MiniDrawer/> */}
        {/* <FillInTheBlanks/> */}

        {/* <App1/> */}
        {/* <Dash/> */}
        {/* <FolderManager/> */}
        {/* <CardsDisplay/> */}
        {/* <TreeView/> */}
        {/* <Flashcard/> */}
        {/* <SingleLine/> */}
        {/* <FillInTheBlanks/>   */}
      </ThemeProvider>
    </div>
  );
}

export default App;
