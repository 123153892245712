// single line question type codeing

import React, { useState, useRef, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import FillInTheBlanks from "../FillInTheBlanks";
import App1 from "../App1";
import axios from "axios";
import TrueFalse from "./TrueFalse";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import TagModal from "./TagModal";
import ImageOcclusion from "./ImageOcclusion";
import StyleIcon from "@mui/icons-material/Style";
import { useNavigate } from "react-router-dom";
import katex from "katex";
import "katex/dist/katex.min.css";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import sanitizeHtml from "sanitize-html";
import { FaChevronDown, FaChevronUp } from "react-icons/fa"; // For the arrow icons

// Import the Clipboard module from Quill
const Clipboard = Quill.import("modules/clipboard");

class CustomClipboard extends Clipboard {
  onPaste(e) {
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const html = clipboardData.getData("text/html");

    if (html) {
      const sanitizedHtml = sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([
          "b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img"
        ]),
        allowedAttributes: false, // Strip all attributes except essential ones
      });

      // Insert the HTML at the cursor position
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, sanitizedHtml);
      }
    } else {
      const text = clipboardData.getData("text/plain");
      // Insert plain text as HTML with line breaks
      const formattedText = `<p>${text.replace(/\n/g, "<br>")}</p>`;
      const range = this.quill.getSelection();
      if (range) {
        this.quill.clipboard.dangerouslyPasteHTML(range.index, formattedText);
      }
    }
  }
}



// Register the custom clipboard
Quill.register("modules/clipboard", CustomClipboard);


const Flashcard = () => {
  const [cards, setCards] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [currentExplanation, setCurrentExplanation] = useState("");
  const [options, setOptions] = useState([""]);
  const [correctOption, setCorrectOption] = useState("");
  const [isAnswer, setIsAnswer] = useState(false);
  const [isExplanation, setIsExplanation] = useState(false);
  const [questionType, setQuestionType] = useState("Single Line Choice");
  const [clozeAnswers, setClozeAnswers] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);
  const inputRef = useRef(null);
  const [collapsed, setCollapsed] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editCardIndex, setEditCardIndex] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState(null); // Rename to reflect it holds the card's ID
  const [editQuestion, setEditQuestion] = useState("");
  const [editAnswers, setEditAnswers] = useState("");
  const [editExplanation, setEditExplanation] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false); // Controls dropdown visibility
  const [selectedOption, setSelectedOption] = useState(''); // State to track selected option
  // Tag Modal States
  const [tagModalOpen, setTagModalOpen] = useState(false);
  const [tagCardIndex, setTagCardIndex] = useState(null);
  const [tagModalPosition, setTagModalPosition] = useState({ top: 0, left: 0 });
  const [collapsedQuestion, setCollapsedQuestion] = useState(false);
  const [collapsedAnswer, setCollapsedAnswer] = useState(false);
  const [collapsedExplanation, setCollapsedExplanation] = useState(false);
  const navigate = useNavigate();

  const { subfolder_id } = useParams();

  useEffect(() => {
    if (subfolder_id) {
      fetchFlashcardsFromServer();
    }
  }, [subfolder_id]);

  const toggleQuestion = () => setCollapsedQuestion(!collapsedQuestion);
  const toggleAnswer = () => setCollapsedAnswer(!collapsedAnswer);
  const toggleExplanation = () =>
    setCollapsedExplanation(!collapsedExplanation);

  const fetchFlashcardsFromServer = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
  
      // Retrieve the access token from session storage
      const token = sessionStorage.getItem('authToken');
  
      // Make the API call with the Authorization header
      const response = await fetch(`${apiUrl}/subfolder/${subfolder_id}/sub/`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
          'Content-Type': 'application/json', // Optional, depending on API requirements
        },
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      const fetchedCards = data.map((item) => ({
        id: item.id, // Ensure each card has an id
        question: item.statement,
        question_type: item.question_type,
        answer: item.answers,
        explanation: item.explanation,
        expanded: false, // Set expanded to false by default
        tags: item.tags, // Store the entire tags array
      }));
  
      setCards(fetchedCards);
      resetFields();
    } catch (error) {
      console.error('Failed to fetch flashcards from server', error);
    }
  };
  

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  

  

  const handleClozeAnswerChange = (index, value) => {
    const newClozeAnswers = [...clozeAnswers];
    newClozeAnswers[index] = value;
    setClozeAnswers(newClozeAnswers);
  };

  const addClozeField = () => {
    setClozeAnswers([...clozeAnswers, ""]);
  };
  const addFlashcardToServer = async (question, answer, explanation) => {
    const payload = {
      statement: question,
      created_by: "Admin",
      question_type: "SUB",
      answers: answer,
      explanation: explanation,
      tags: [],
    };
    const token = sessionStorage.getItem("authToken");
    
  
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const response = await fetch(`${apiUrl}/subfolder/${subfolder_id}/sub/`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the Authorization header
          "Content-Type": "application/json", // Optional, depending on API requirements
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Failed to add flashcard to server");
      }
  
      const addedFlashcard = await response.json();
      console.log("Flashcard added:", addedFlashcard);
  
      // Success: Reset fields, show success message, fetch new cards
      alert("Flashcard added successfully!");
      resetFields();
      fetchFlashcardsFromServer();
    } catch (error) {
      console.error("Failed to add flashcard to server", error);
      alert("Failed to add flashcard, please try again.");
    }
  };
  

  
  const handleKeyDown = async (e) => {
    if (e.key === 'ArrowRight' && !isAnswer && !isExplanation) {
      e.preventDefault();
      setIsAnswer(true);
      setIsExplanation(false);
      setCurrentAnswer(''); // Reset the answer field
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else if (e.key === 'Enter' && isAnswer && !isExplanation) {
      e.preventDefault();
      if (currentQuestion.trim() && currentAnswer.trim()) {
        setIsAnswer(false);
        setIsExplanation(true);
        setCurrentExplanation(''); // Reset the explanation field
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    } else if (e.key === 'Enter' && isExplanation) {
      e.preventDefault();
      await handleAddFlashcard(); // Call the new function to handle submission
    }
  };
  
  const handleAddFlashcard = async () => {
    // Check if question and answer are filled, explanation is optional
    if (!currentQuestion.trim() || !currentAnswer.trim()) {
      alert(
        "Please fill in the question and answer fields before adding a flashcard."
      );
      return;
    }

    // Use an empty string for explanation if it's not provided
    const explanationToSend = currentExplanation.trim() || ""; // Default to empty string if no explanation

    try {
      // Pass the explanationToSend, even if it's empty
      await addFlashcardToServer(
        currentQuestion,
        currentAnswer,
        explanationToSend
      );

      // Add the flashcard to the local state
      setCards([
        ...cards,
        {
          question: currentQuestion.trim(),
          answer: currentAnswer.trim(),
          explanation: currentExplanation.trim() || "", // Ensure explanation is empty if not provided
          questionType,
          expanded: false,
        },
      ]);
    } catch (error) {
      console.error("Failed to add flashcard", error);
    }
  };

  
  const resetFields = () => {
    setCurrentQuestion("");
    setCurrentAnswer("");
    setCurrentExplanation("");
    setIsAnswer(false);
    setIsExplanation(false);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };
  

  
  const handleToolbarClick = (type) => {
    setQuestionType(type);
    setCurrentQuestion("");
    setCurrentAnswer("");
    setCurrentExplanation("");
    setOptions([""]);
    setCorrectOption("");
    setIsAnswer(false);
    setIsExplanation(false);
    setClozeAnswers([]);
    setUserAnswers([]);
    if (inputRef.current) {
      inputRef.current.value = "";
    }
  };

  const addCloze = () => {
    if (currentQuestion.includes(currentAnswer)) {
      const clozeQuestion = currentQuestion.replace(currentAnswer, "_");
      setCurrentQuestion(clozeQuestion);
      setClozeAnswers([...clozeAnswers, currentAnswer]);
      setCurrentAnswer("");
    }
  };

  

  const handleUserAnswerChange = (index, value) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[index] = value;
    setUserAnswers(newUserAnswers);
  };

  const toggleCardExpand = (index) => {
    const newCards = [...cards];
    newCards[index].expanded = !newCards[index].expanded;
    setCards(newCards);
  };

  const openEditModal = (index) => {
    setEditCardIndex(index);
    setEditQuestion(cards[index].question);
    setEditAnswers(cards[index].answer);
    setEditExplanation(cards[index].explanation);
    setEditModalOpen(true);
  };

  const handleEditChange = (index, value) => {
    const updatedAnswers = [...userAnswers]; // Create a copy of the answers array
    updatedAnswers[index] = value; // Update the answer at the specified index
    setUserAnswers(updatedAnswers); // Update the state with the updated answers
  };
  
  const handleUpdateCard = async () => {
    const updatedCard = {
      statement: editQuestion,
      created_by: "Admin",
      question_type: "SUB",
      answers: editAnswers,
      explanation: editExplanation,
      tags: [],
    };

    try {
      const cardId = cards[editCardIndex].id;
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("authToken"); // Retrieve the token from localStorage or your preferred storage method
      const response = await axios.put(
        `${apiUrl}/subfolder/${subfolder_id}/sub/${cardId}/`,
        updatedCard,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add the Bearer token here
            "Content-Type": "application/json",
          },
        }
      );

      const updatedCards = [...cards];
      updatedCards[editCardIndex] = {
        ...updatedCards[editCardIndex],
        question: currentQuestion,
        answer: currentAnswer,
        explanation: currentExplanation,
      };
      // setCards(updatedCards);
      setEditModalOpen(false);
      fetchFlashcardsFromServer();
    } catch (error) {
      console.error("Failed to update flashcard on server", error);
    }
  };


  const handleCloseModal = () => {
    setEditModalOpen(false);
  };

  const openDeleteDialog = (id) => {
    setDeleteCardId(id); // Use the actual card's id
    console.log(id);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCard = async () => {
    try {
      const apiUrl = process.env.REACT_APP_BASE_URL;
      const token = sessionStorage.getItem("authToken");
      const response = await axios.delete(
         `${apiUrl}/subfolder/${subfolder_id}/sub/${deleteCardId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the Bearer token in the Authorization header
          },
        }
      );
      const updatedCards = cards.filter((card) => card.id !== deleteCardId);
      setCards(updatedCards);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Failed to delete flashcard from server", error);
    }
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  // Tag Modal Functions
  const openTagModal = (index, event) => {
    const iconRect = event.target.getBoundingClientRect();
    setTagCardIndex(index);
    setTagModalPosition({
      top: iconRect.bottom + window.scrollY,
      left: iconRect.left + window.scrollX,
    });
    setTagModalOpen(true);
  };

  const handleTagAdded = (cardId, newTag) => {
    const updatedCards = cards.map((card) => {
      if (card.id === cardId) {
        return { ...card, tags: [...card.tags, newTag] };
      }
      return card;
    });
    setCards(updatedCards);
  };

  // const handleIconClick = () => {
  //   const hardCodedText = "sub"; // replace with your hard-coded text
  //   navigate(
  //     `/practiceparticularcard/${subfolder_id}/${encodeURIComponent(
  //       hardCodedText
  //     )}`
  //   );
  // };
  const handleIconClick = () => {
    // Toggle dropdown visibility when icon is clicked
    setDropdownVisible(!dropdownVisible);
  };

  const handleDropdownChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    if (selectedValue === 'practiceCard') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "sub";
      navigate(

        `/practiceparticularcard/${subfolder_id}/${encodeURIComponent(
          hardCodedText
        )}`      // Example: history.push('/practice-card');
      )
    }

    else if (selectedValue === 'spacedrep') {
      // Logic to navigate to practice card page (you can add navigation here)
      const hardCodedText = "sub";
      navigate(

        `/practicespacedrep/${subfolder_id}/${encodeURIComponent(
          hardCodedText
        )}`      // Example: history.push('/practice-card');
      )
    }

    // Hide dropdown after selection
    setDropdownVisible(false);
  };
  const renderContent = (html) => {
    if (!html) return null;
  
    // Split content into LaTeX and non-LaTeX parts
    const parts = html.split(/(\$\$.+?\$\$|\$.+?\$)/g);
  
    return (
      <div>
        {parts.map((part, index) => {
          // Handle block-level LaTeX ($$...$$)
          if (part.startsWith("$$") && part.endsWith("$$")) {
            const latex = part.slice(2, -2); // Remove $$ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: true });
              return (
                <div
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content block-latex"
                />
              );
            } catch (error) {
              console.error("Block LaTeX rendering error:", error);
              return <div key={index} className="error-content">{part}</div>;
            }
          }
  
          // Handle inline LaTeX ($...$)
          if (part.startsWith("$") && part.endsWith("$")) {
            const latex = part.slice(1, -1); // Remove $ delimiters
            try {
              const renderedLatex = katex.renderToString(latex, { displayMode: false });
              return (
                <span
                  key={index}
                  dangerouslySetInnerHTML={{ __html: renderedLatex }}
                  className="rendered-content inline-latex"
                />
              );
            } catch (error) {
              console.error("Inline LaTeX rendering error:", error);
              return <span key={index} className="error-content">{part}</span>;
            }
          }
  
          // Handle non-LaTeX content
          try {
            const formattedContent = part.replace(/\n/g, "<br />"); // Replace newlines
            const allowedTags = ["b", "i", "em", "strong", "span", "br", "u", "sub", "sup", "a", "p", "img"];
            const allowedAttributes = {
              img: ["src", "alt"],
              a: ["href", "target"],
              span: ["style"],
              p: ["style"],
            };
  
            // Use sanitize-html or DOMPurify with selective sanitization
            const sanitizedContent = sanitizeHtml(formattedContent, {
              allowedTags,
              allowedAttributes,
              transformTags: {
                'p': 'span', // Convert <p> tags to <span>
                'div': 'span', // Convert <div> tags to <span>
              },
            });
  
            return (
              <span
                key={index}
                dangerouslySetInnerHTML={{ __html: sanitizedContent }}
                className="rendered-content inline-text"
              />
            );
          } catch (error) {
            console.error("HTML rendering error:", error);
            return <span key={index} className="error-content">{part}</span>;
          }
        })}
      </div>
    );
  };
  
  
  return (
    <div style={{ display: "flex" }}>
      <div className="flashcard-container">
        <div className="input-container">
          {questionType === "True/False" && <TrueFalse />}
          {questionType === "Fill in the Blanks" && <FillInTheBlanks />}
          {questionType === "Multiple Choice" && <App1 />}
          {questionType === "Image Occlusion" && <ImageOcclusion />}
          {questionType === "Single Line Choice" && (
            <div>
              <div style={{ position: "relative", marginBottom: "20px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h1>
                    <b>Single Line Questions</b>
                  </h1>

                  {/* Style Icon */}
                  <div style={{ position: "relative" }}>
                    <StyleIcon
                      onClick={handleIconClick}
                      style={{ cursor: "pointer" }}
                    />

                    {/* Options (only visible when dropdownVisible is true) */}
                    {dropdownVisible && (
                      <div
                        style={{
                          position: "absolute",
                          top: "30px", // Adjust position based on your layout
                          right: "-30px", // Adjusted to move it further to the right
                          zIndex: 1,
                          backgroundColor: "#e3f2fd", // Light bluish shade
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          padding: "0", // Remove extra padding here
                        }}
                      >
                        <div
                          onClick={() =>
                            handleDropdownChange({
                              target: { value: "practiceCard" },
                            })
                          }
                          style={{
                            cursor: "pointer",
                            padding: "10px 15px", // Increased padding for better coverage
                            transition: "background-color 0.3s ease",
                            whiteSpace: "nowrap", // Prevent text from wrapping
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor = "#bbdefb")
                          } // Lighter blue on hover
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "transparent")
                          }
                        >
                          Practice FlashCard
                        </div>
                        <div
                          onClick={() =>
                            handleDropdownChange({
                              target: { value: "spacedrep" },
                            })
                          }
                          style={{
                            cursor: "pointer",
                            padding: "10px 15px", // Increased padding for better coverage
                            transition: "background-color 0.3s ease",
                            whiteSpace: "nowrap", // Prevent text from wrapping
                          }}
                          onMouseEnter={(e) =>
                            (e.currentTarget.style.backgroundColor = "#bbdefb")
                          } // Lighter blue on hover
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.backgroundColor =
                              "transparent")
                          }
                        >
                          Practice with Spaced Repetition
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* Question Field with Arrow */}
                <div style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={toggleQuestion}
                  >
                    <div style={{ marginRight: "10px" }}>
                      {collapsedQuestion ? <FaChevronDown /> : <FaChevronUp />}
                    </div>
                    <div>Question</div>
                  </div>
                  {/* Initially shown and collapsible */}
                  <div
                    style={{ display: collapsedQuestion ? "none" : "block" }}
                  >
                    <ReactQuill
                      value={currentQuestion}
                      onChange={setCurrentQuestion}
                      placeholder="Enter your question"
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "strike"],
                          ["blockquote", "code-block"],
                          [{ header: 1 }, { header: 2 }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          [{ direction: "rtl" }],
                          [{ size: ["small", false, "large", "huge"] }],
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [{ color: [] }, { background: [] }],
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "code-block",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "image",
                        "video",
                        "color",
                        "background",
                        "align",
                        "script",
                        { name: "preserve-spaces", type: "inline" },
                      ]}
                      style={{
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                    />
                  </div>
                </div>

                {/* Answer Field with Arrow */}
                <div style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={toggleAnswer}
                  >
                    <div style={{ marginRight: "10px" }}>
                      {collapsedAnswer ? <FaChevronDown /> : <FaChevronUp />}
                    </div>
                    <div>Answer</div>
                  </div>
                  {/* Initially shown and collapsible */}
                  <div style={{ display: collapsedAnswer ? "none" : "block" }}>
                    <ReactQuill
                      value={currentAnswer}
                      onChange={setCurrentAnswer}
                      placeholder="Enter your answer"
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "strike"],
                          ["blockquote", "code-block"],
                          [{ header: 1 }, { header: 2 }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          [{ direction: "rtl" }],
                          [{ size: ["small", false, "large", "huge"] }],
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [{ color: [] }, { background: [] }],
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "code-block",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "image",
                        "video",
                        "color",
                        "background",
                        "align",
                        "script",
                        { name: "preserve-spaces", type: "inline" },
                      ]}
                      style={{
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                    />
                  </div>
                </div>

                {/* Explanation Field with Arrow */}
                <div style={{ marginBottom: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={toggleExplanation}
                  >
                    <div style={{ marginRight: "10px" }}>
                      {collapsedExplanation ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronUp />
                      )}
                    </div>
                    <div>Explanation</div>
                  </div>
                  {/* Initially shown and collapsible */}
                  <div
                    style={{ display: collapsedExplanation ? "none" : "block" }}
                  >
                    <ReactQuill
                      value={currentExplanation}
                      onChange={setCurrentExplanation}
                      placeholder="Enter your explanation"
                      modules={{
                        toolbar: [
                          ["bold", "italic", "underline", "strike"],
                          ["blockquote", "code-block"],
                          [{ header: 1 }, { header: 2 }],
                          [{ list: "ordered" }, { list: "bullet" }],
                          [{ script: "sub" }, { script: "super" }],
                          [{ indent: "-1" }, { indent: "+1" }],
                          [{ direction: "rtl" }],
                          [{ size: ["small", false, "large", "huge"] }],
                          [{ header: [1, 2, 3, 4, 5, 6, false] }],
                          [{ color: [] }, { background: [] }],
                          [{ font: [] }],
                          [{ align: [] }],
                          ["clean"],
                        ],
                      }}
                      formats={[
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "code-block",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "image",
                        "video",
                        "color",
                        "background",
                        "align",
                        "script",
                        { name: "preserve-spaces", type: "inline" },
                      ]}
                      style={{
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                    />
                  </div>
                </div>
              </div>
              <Button
                onClick={handleAddFlashcard} // Call the new function on button click
                variant="contained"
                sx={{
                  backgroundColor: "#1565c0",
                  color: "white",
                  "&:hover": { backgroundColor: "#0d47a1" },
                }}
              >
                add flashcard
              </Button>

              <div>
                <ul className="card-list">
                  {cards.map((card, index) => (
                    <li
                      key={index}
                      className="card"
                      style={{
                        background:
                          "linear-gradient(to right, #dfe6f9, rgb(124 182 246))",
                        position: "relative",
                        padding: "16px",
                        borderRadius: "8px",
                        marginBottom: "12px",
                        wordBreak: "break-word",
                        overflowWrap: "break-word",
                        maxWidth: "calc(100% - 80px)", // Add maxWidth for controlled width
                        whiteSpace: "pre-wrap", // Preserve whitespace and allow wrapping
                      }}
                    >
                      {/* Question */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          paddingRight: "40px", // Adjust padding if needed
                          maxWidth: "calc(100% - 70px)",
                          whiteSpace: "pre-wrap",
                        }}
                      >
                        <strong style={{ marginRight: "2px" }}>Q:</strong>
                        <span style={{ flex: 1 }}>
                          <strong>{renderContent(card.question)}</strong>
                        </span>
                      </div>

                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          display: "flex",
                          gap: "10px",
                        }}
                      >
                        <i
                          className={`bi ${
                            card.expanded
                              ? "bi-arrows-angle-contract"
                              : "bi-arrows-angle-expand"
                          }`}
                          title={card.expanded ? "Contract" : "Expand"}
                          onClick={() => toggleCardExpand(index)}
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="bi bi-pencil"
                          title="Edit"
                          onClick={() => openEditModal(index)}
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="bi bi-trash"
                          title="Delete"
                          onClick={() => openDeleteDialog(card.id)}
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="bi bi-tag"
                          title="Tag"
                          onClick={(e) => openTagModal(index, e)}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </div>

                      {card.expanded && (
                        <>
                          <>
                            {/* Answer */}
                            <div
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                wordBreak: "break-word",
                                overflowWrap: "break-word",
                                whiteSpace: "pre-wrap", // Preserve whitespace and allow wrapping
                              }}
                            >
                              <strong style={{ marginRight: "5px" }}>A:</strong>
                              <span style={{ flex: 1 }}>
                                {renderContent(card.answer)}
                              </span>
                            </div>

                            {card.explanation && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-start",
                                  wordBreak: "break-word",
                                  overflowWrap: "break-word",
                                  whiteSpace: "pre-wrap", // Preserve whitespace and allow wrapping
                                }}
                              >
                                <strong style={{ marginRight: "5px" }}>
                                  Explanation:
                                </strong>
                                <span style={{ flex: 1 }}>
                                  {renderContent(card.explanation)}
                                </span>
                              </div>
                            )}
                            <div
                              style={{
                                position: "absolute",
                                bottom: "10px",
                                right: "10px",
                              }}
                            >
                              <p>
                                Tags:
                                {card.tags.map((tag, index) => (
                                  <span key={tag.id}>
                                    [{tag.name}
                                    {index < card.tags.length - 1 ? ", " : ""}]
                                  </span>
                                ))}
                              </p>
                            </div>
                          </>
                        </>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`vertical-toolbar ${collapsed ? "collapsed" : ""}`}
        style={{ background: "linear-gradient(to right, #142be1, #44a6e9)" }}
      >
        <i
          className="bi bi-card-list"
          title="Single Line Choice Card"
          onClick={() => handleToolbarClick("Single Line Choice")}
        ></i>
        <i
          className="bi bi-pencil"
          title="Fill in the Blanks"
          onClick={() => handleToolbarClick("Fill in the Blanks")}
        ></i>
        <i
          className="bi bi-toggle-on"
          title="True/False"
          onClick={() => handleToolbarClick("True/False")}
        ></i>
        <i
          className="bi bi-ui-radios"
          title="Multiple Choice Q&A"
          onClick={() => handleToolbarClick("Multiple Choice")}
        ></i>
        <i
          className="bi bi-textarea-resize"
          title="Multiple Line Choice Card"
          onClick={() => handleToolbarClick("Multiple Line Choice")}
        ></i>
        <i
          className="bi bi-image"
          title="Image Occlusion"
          onClick={() => handleToolbarClick("Image Occlusion")}
        ></i>
        <i className="bi bi-trash" title="Delete"></i>
        <i className="bi bi-type-h1" title="Heading"></i>
        <i className="bi bi-plus" title="Add"></i>
        <i
          className="bi bi-chat-dots"
          title="Collapse"
          onClick={toggleCollapse}
        ></i>
      </div>
      <Dialog open={editModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Edit Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edit your flashcard details below.
          </DialogContentText>
          <label>Question</label>
          <ReactQuill
            label="Question"
            variant="outlined"
            value={editQuestion}
            onChange={setEditQuestion}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"], // remove formatting button
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "color",
              "background",
              "align",
              "script",
              { name: "preserve-spaces", type: "inline" },
            ]}
          />
          <label>Answer</label>
          <ReactQuill
            label="Answer"
            variant="outlined"
            value={editAnswers}
            onChange={setEditAnswers}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"], // remove formatting button
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "color",
              "background",
              "align",
              "script",
              { name: "preserve-spaces", type: "inline" },
            ]}
          />
          <label>Explanation</label>
          <ReactQuill
            label="Explanation"
            variant="outlined"
            value={editExplanation}
            onChange={setEditExplanation}
            modules={{
              toolbar: [
                ["bold", "italic", "underline", "strike"], // toggled buttons
                ["blockquote", "code-block"],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }], // superscript/subscript
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                [{ direction: "rtl" }], // text direction
                [{ size: ["small", false, "large", "huge"] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                [{ font: [] }],
                [{ align: [] }],
                ["clean"], // remove formatting button
              ],
            }}
            formats={[
              "header",
              "font",
              "size",
              "bold",
              "italic",
              "underline",
              "strike",
              "blockquote",
              "code-block",
              "list",
              "bullet",
              "indent",
              "link",
              "image",
              "video",
              "color",
              "background",
              "align",
              "script",
              { name: "preserve-spaces", type: "inline" },
            ]}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleUpdateCard}>Save</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Flashcard</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this flashcard?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button onClick={handleDeleteCard}>Delete</Button>
        </DialogActions>
      </Dialog>
      <TagModal
        open={tagModalOpen}
        position={tagModalPosition}
        onClose={() => setTagModalOpen(false)}
        subfolderId={subfolder_id}
        cardId={cards[tagCardIndex]?.id}
        cardQuestionType={cards[tagCardIndex]?.question_type}
        onTagAdded={handleTagAdded}
      />
    </div>
  );
};

export default Flashcard;
