import React, { useState, useEffect } from 'react';
import TreeNode from './TreeNode'; // Import the TreeNode component
import { Box, CircularProgress, Typography } from '@mui/material';

const FolderHierarchyPage = () => {
  const [folderTree, setFolderTree] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedFolderId, setSelectedFolderId] = useState(null); // Track selected folder ID



  const apiUrl = process.env.REACT_APP_BASE_URL;

   // Fetch the hierarchical folder structure from API
   const fetchTreeData = () => {
    setLoading(true);
    const token = sessionStorage.getItem('authToken');
    fetch(`${apiUrl}/folders`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        'Content-Type': 'application/json', // Optional, depending on API requirements
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setFolderTree(data); // Assuming 'data' is an array of folders with nested subfolders
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching folder data:', error);
        setLoading(false);
      });
  };



  useEffect(() => {
    fetchTreeData(); // Fetch folder data when the component mounts
  }, []);

  const moveNode = (sourceId, destinationId) => {
    // Logic for moving a folder or subfolder
  };

  const onSelect = (folderId) => {
    setSelectedFolderId(folderId); // Set selected folder ID
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Folder Hierarchy
      </Typography>

      {loading ? (
        <CircularProgress /> // Show a loader while fetching the data
      ) : folderTree.length === 0 ? (
        <Typography>No folders found.</Typography> // Show a message if no folders exist
      ) : (
        <div className="folder-hierarchy">
          {folderTree.map((folder) => (
            <TreeNode
              key={folder.id}
              node={folder} // Pass each folder to TreeNode
              moveNode={moveNode} // Function to handle drag-and-drop folder movement
              onSelect={onSelect} // Function to handle folder selection
              selectedFolderId={selectedFolderId} // Highlight selected folder
              fetchTreeData={fetchTreeData} // Fetch tree data after any change
            />
          ))}
        </div>
      )}
    </Box>
  );
};

export default FolderHierarchyPage;
