import React, { useState } from 'react';
import { Button, TextField, Typography, Container, Box, Paper } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';


const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
const {userid} = useParams( );
const {token} = useParams( );


  let navigate = useNavigate();

  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
      background: {
        default: '#ffffff',
      },
    },
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      // Assuming token is passed via query parameters, adjust as necessary
      const urlParams = new URLSearchParams(window.location.search);
      // const token = urlParams.get('token');

      const response = await axios.post(`http://warals1.ddns.net:9008/set-new-password/${userid}/${token}/`, {
       
        password,
        confirm_password: confirmPassword,
      });

      console.log('Password Reset Successful:', response.data);
      setSuccess('Password has been reset successfully.');
      setError(''); // Clear error
      // Redirect to login page after success
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      console.error('Password Reset Failed:', error);
      setError('Failed to reset password');
      setSuccess(''); // Clear success message
    }
  };

  const handleCancel = () => {
    navigate('/login'); // Redirect to login page
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="sm">
        <Paper elevation={3} style={{ padding: '20px', marginTop: '70px', minHeight: '50vh' }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            component="form"
            onSubmit={handleSubmit}
          >
            <Typography variant="h5" component="h1" gutterBottom>
              Reset Password
            </Typography>
            {error && (
              <Typography variant="body2" color="error" align="center" style={{ marginBottom: 10 }}>
                {error}
              </Typography>
            )}
            {success && (
              <Typography variant="body2" color="primary" align="center" style={{ marginBottom: 10 }}>
                {success}
              </Typography>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="New Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm New Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Box display="flex" justifyContent="space-between" width="50%" style={{ marginTop: 20 }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Set Password
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default ResetPassword;
